






import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import FormBase from '@/shared/classes/form/form-base'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import Form from '@/shared/components/form/Form.vue'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import moment from 'moment'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class InductCollectedHours extends Vue {
  @Prop() meta: any
  form: FormBase = new FormBase()
    .setEndpoint(`company/${ this.company.slug }/work-times/induct-collected-hours`)
    .setFields([
      new Field()
        .setType(FieldTypes.radio)
        .setKey('type')
        .setMeta({
          row: true,
          items: [
            { value: 'induct', label: __('company.components.views.reports.collected-hours.induct-collected-hours.form.fields.type.induct') },
            { value: 'deduct', label: __('company.components.views.reports.collected-hours.induct-collected-hours.form.fields.type.deduct') },
          ]
        }),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('date')
        .setTitle(__('company.components.views.reports.collected-hours.induct-collected-hours.form.fields.date'))
        .setMeta({
          max: moment().format('YYYY-MM-DD'),
        }),
      new SearchableField()
        .setKey('user_uuid')
        .setTitle(__('company.components.views.reports.collected-hours.induct-collected-hours.form.fields.employee'))
        .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 }),
      new Field()
        .setType(FieldTypes.number)
        .setKey('amount')
        .setTitle(__('company.components.views.reports.collected-hours.induct-collected-hours.form.fields.amount')),
    ])
    .setInitialValues({
      type: 'induct',
      date: this.meta.date ? this.meta.date : moment.utc().format('YYYY-MM-DD'),
      user_uuid: this.meta.user,
    })
    .setOnSuccess(() => {
      this.$store.dispatch(GlobalActions.closeDialog)
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.components.views.reports.collected-hours.induct-collected-hours.form.success')
      })
      this.meta.onSuccess && this.meta.onSuccess()
    })

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
