





































































import { Component, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import ReportService, { GenerateType } from '@/shared/modules/report/ReportService'
import __ from '@/shared/helpers/__'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import moment from 'moment'
import FormField from '@/shared/components/form/FormField.vue'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import { DataTableHeader } from 'vuetify'
import PageHeader from '@/admin/components/PageHeader.vue'
import _ from 'lodash'
import { GlobalActions } from '@/shared/store/global/global.actions'
import InductCollectedHours from '@/company/components/reports/InductCollectedHours.vue'

@Component({
  components: { PageHeader, FormField },
  methods: { __ }
})
export default class CollectedHours extends Vue {
  types = GenerateType
  fromField: Field = new Field().setType(FieldTypes.datePicker).setKey('from').setTitle(__('company.views.reports.index.tabs.collected-hours.form.fields.from')).setDense(true)
  toField: Field = new Field()
    .setType(FieldTypes.datePicker)
    .setKey('to')
    .setTitle(__('company.views.reports.index.tabs.collected-hours.form.fields.to'))
    .setDense(true)
    .setMeta({
      minKey: () => 'from'
    })
  employeeField: Field = new SearchableField().setKey('employee_uuid').setTitle(__('company.views.reports.index.tabs.collected-hours.form.fields.employee')).setDense(true).loadItems({
    endpoint: `company/${ this.company.slug }/employees`,
    value: 'uuid',
    title: 'full_name',
    perPage: 20
  })
  data: any = null
  loading: GenerateType | null = null
  from: any = null
  to: any = null

  form: FormBase = new FormBase()
    .setData({
      from: moment.utc().startOf('month').format('YYYY-MM-DD'),
      to: moment.utc().endOf('month').format('YYYY-MM-DD'),
      employee_uuid: '',
    })

  generate(type: GenerateType): void {
    this.loading = type
    ReportService.collectedHours(this.company, type, this.form.data.from, this.form.data.to, this.form.data.employee_uuid)
      .then((response: any) => {
        this.form.setErrors({})
        if (type === GenerateType.pivot) {
          this.data = response.data
          this.from = _.cloneDeep(this.form.data.from)
          this.to = _.cloneDeep(this.form.data.to)
          return
        }

        downloadFile(response, `collected-hours-report.${ type }`)
      })
      .catch((error: any) => this.form.catchErrors(error))
      .finally(() => this.loading = null)
  }

  openInductCollectedHoursModal({ user, date }: any = null) {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: InductCollectedHours,
      maxWidth: 500,
      meta: {
        user,
        date,
        onSuccess: () => {
          this.generate(GenerateType.pivot)
        }
      }
    })
  }

  get headers(): DataTableHeader[] {
    return [
      {
        value: 'name',
        text: __('company.views.reports.index.tabs.collected-hours.table.headers.user'),
        sortable: false,
      },
      {
        value: 'end',
        text: __('company.views.reports.index.tabs.collected-hours.table.headers.collected-hours'),
        sortable: false,
        align: 'end',
      }
    ]
  }

  get items(): any {
    const items = Object.keys(this.data.users).map((userUuid: string) => ({
      name: this.data.users[userUuid].name,
      end: this.data.users[userUuid].end,
    }))

    items.push({
      name: __('company.views.reports.index.tabs.collected-hours.table.summary.total'),
      end: this.data.total,
    })

    return items
  }

  get detailHeaders(): DataTableHeader[] {
    return [
      {
        value: 'user',
        text: __('company.views.reports.index.tabs.collected-hours.table.headers.user'),
        sortable: false,
      },
      {
        value: 'date',
        text: __('company.views.reports.index.tabs.collected-hours.table.headers.date'),
        sortable: false,
      },
      {
        value: 'condition',
        text: __('company.views.reports.index.tabs.collected-hours.table.headers.condition'),
        sortable: false,
      },
      {
        value: 'collected_hours_per_day',
        text: __('company.views.reports.index.tabs.collected-hours.table.headers.collected-hours-per-day'),
        sortable: false,
        align: 'end',
      },
      {
        value: 'collected_hours',
        text: __('company.views.reports.index.tabs.collected-hours.table.headers.collected-hours'),
        sortable: false,
        align: 'end',
      },
      {
        value: 'actions',
        text: '',
        sortable: false,
        align: 'end'
      }
    ]
  }

  get detailItems(): any {
    const data: any = []
    Object.keys(this.data.details).forEach((uuid: string) => {
      Object.keys(this.data.details[uuid].dates).forEach((date: string) => {
        data.push({
          user: uuid,
          date,
          condition: this.data.details[uuid].dates[date].condition,
          collected_hours_per_day: this.data.details[uuid].dates[date].collected_hours_per_day,
          collected_hours: this.data.details[uuid].dates[date].collected_hours,
          actions: '',
        })
      })
    })

    return data
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
